import PropTypes from 'prop-types';
import Icon from './Icon';

const SEARCH_SVG = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73943 5.74334C7.03825 4.44451 8.79983 3.71484 10.6366 3.71484C12.4735 3.71484 14.235 4.44451 15.5339 5.74334C16.8327 7.04216 17.5624 8.80374 17.5624 10.6406C17.5624 12.4774 16.8327 14.2389 15.5339 15.5378C14.235 16.8366 12.4735 17.5663 10.6366 17.5663C8.79983 17.5663 7.03825 16.8366 5.73943 15.5378C4.44061 14.2389 3.71094 12.4774 3.71094 10.6406C3.71094 8.80374 4.44061 7.04216 5.73943 5.74334ZM10.6366 6.28627C9.48182 6.28627 8.37429 6.74503 7.55771 7.56161C6.74112 8.3782 6.28237 9.48573 6.28237 10.6406C6.28237 11.7954 6.74112 12.9029 7.55771 13.7195C8.37429 14.5361 9.48182 14.9948 10.6366 14.9948C11.7915 14.9948 12.899 14.5361 13.7156 13.7195C14.5322 12.9029 14.9909 11.7954 14.9909 10.6406C14.9909 9.48573 14.5322 8.3782 13.7156 7.56161C12.899 6.74503 11.7915 6.28627 10.6366 6.28627Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7203 14.7223C15.2224 14.2202 16.0365 14.2202 16.5386 14.7223L19.9069 18.0906C20.409 18.5927 20.409 19.4068 19.9069 19.9089C19.4048 20.411 18.5907 20.411 18.0886 19.9089L14.7203 16.5406C14.2182 16.0385 14.2182 15.2244 14.7203 14.7223Z"
    />
  </svg>
);

export default function IcSearch01({className}) {
  return (
    <Icon className={className} label="Search">
      {SEARCH_SVG}
    </Icon>
  );
}

IcSearch01.propTypes = {
  className: PropTypes.string,
};
IcSearch01.defaultProps = {
  className: '',
};
